export default function (id, username, role = null, personId = null, relationalUser = false) {
  let window = id ? `usuario.${id}` : 'usuario.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Usuário' + (username ? ` #${username}` : ''),
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window usuario-window',
    contentClass: 'overflow-hidden__',
    props: {
      id: id,
      router: this.router || this.$router,
      role: role,
      personId: personId,
      relationalUser: relationalUser
    }
  }, () => import('../components/window/Usuario.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        updated: (wid, user) => {
          console.log('updated')
          this.load && this.load()
        }
      })
    }) // return wid
}
